/** @format */

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Switch,
	useParams,
} from "react-router-dom";
import "./App.css";
import MainComponentClinic from "./Components/MainComponent";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<MainComponentClinic />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
