/** @format */

import React, { useEffect } from "react";
import {
	Container,
	Navbar,
	Nav,
	Button,
	Card,
	Row,
	Col,
} from "react-bootstrap";
import CountUp from "react-countup";
import "./styles.css";
import { Helmet } from "react-helmet";

const servicesData = [
	{
		title: "Kidney Stones",
		description:
			"Treatment for the formation of small, hard deposits in the kidneys, which can cause severe pain and discomfort.",
		imagePath: "/kidney_stones.jpg",
	},
	{
		title: "Sinus, Allergy, Asthma",
		description:
			"Remedies for sinus infections, allergies, and asthma, focusing on reducing symptoms like congestion and difficulty breathing.",
		imagePath: "/sinus_allergy_asthma.jpg",
	},
	{
		title: "Back Pain and Sciatica",
		description:
			"Relief for chronic back pain and sciatica, aiming to improve mobility and reduce discomfort.",
		imagePath: "/back_pain_sciatica.png",
	},
	{
		title: "Stomach Ache and Acidity",
		description:
			"The treatment aims to alleviate pain and discomfort in the abdominal area while managing excessive stomach acid levels, addressing issues like indigestion, heartburn, and acid reflux to provide holistic relief and improve digestive health.",
		imagePath: "/stomachache_and_acidity.jpg",
	},
	{
		title: "Psoriasis, Skin Problems",
		description:
			"Solutions for skin conditions like psoriasis, which involve inflammation and scaling of the skin.",
		imagePath: "/psoriasis_skin_problems.jpg",
	},
	{
		title: "Hair Fall, Dandruff",
		description:
			"Treatments targeting hair loss and dandruff, focusing on improving scalp health and hair strength.",
		imagePath: "/hair_fall_dandruff.jpg",
	},
	{
		title: "Headache, Migraine",
		description:
			"Approaches to alleviate headaches and migraines, focusing on reducing frequency and severity.",
		imagePath: "/headache_migraine.jpg",
	},
	{
		title: "Diabetes, BP (Blood Pressure), Sugar",
		description:
			"Management of diabetes and high blood pressure to maintain healthy blood sugar and pressure levels.",
		imagePath: "/diabetes_bp_sugar.jpg",
	},
	{
		title: "Thyroid",
		description:
			"Treatment options for thyroid-related issues, aiming to balance hormone levels and improve metabolism.",
		imagePath: "/thyroid.jpg",
	},
	{
		title: "Joint Pain, Arthritis",
		description:
			"Remedies for joint pain and arthritis, focusing on reducing inflammation and improving joint function.",
		imagePath: "/joint_pain_arthritis.jpg",
	},
	{
		title: "Obesity",
		description:
			"Weight management strategies to help reduce obesity and associated health risks.",
		imagePath: "/obesity.jpg",
	},
];

function MainComponentClinic() {
	useEffect(() => {
		const images = document.querySelectorAll(".gallery-item img");
		images.forEach((img) => {
			img.onload = () => {
				img.classList.add("loaded");
			};
		});
	}, []);

	return (
		<>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Physician",
						name: "Dr. Pathuri Rajashekhar Reddy",
						medicalSpecialty: "Homeopathy",
						address: {
							"@type": "PostalAddress",
							streetAddress:
								"Tirumala Apartments, Old Sai Baba Temple Rd, behind New Bus stand Area, NGO'S Colony, Kamareddy, Telangana 503111, India",
						},
						affiliation: "Hospital",
						contactPoint: {
							"@type": "ContactPoint",
							telephone: "9494260222",
							contactType: "Appointment",
						},
					})}
				</script>
				<title>
					Dr. Pathuri Rajashekhar Reddy | Bhuvan's Homeopathy Clinic
				</title>
				<meta
					name="description"
					content="Book your appointment with Dr. Pathuri Rajashekhar Reddy, a top Homeopathy Physician in Kamareddy, at Bhuvan's Homeopathy Clinic. Call @+91 9494260222."
				/>
				<meta
					name="keywords"
					content="doctor, specialist , Homeopathy, Kamareddy, Bhuvan's Homeopathy Clinic,Kidney Stones, Sinus, Allergy, Asthma, Back Pain and Sciatica, Stomach Ache and Acidity, 
					Psoriasis, Skin Problems, Hair Fall, Dandruff, Headache, Migraine, Diabetes, BP (Blood Pressure), Sugar, Thyroid, Joint Pain, Arthritis, Obesity"
				/>
			</Helmet>

			<div className="App">
				<Navbar expand="lg" className="navbar-bg">
					<Navbar.Brand href="/" className="offset-md-1">
						<img
							alt="No Img"
							src="/bhuvanslogo.png"
							width="70"
							height="60"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Nav.Link href="#home" className="custom-nav-link">
								HOME
							</Nav.Link>
							<Nav.Link href="#aboutus" className="custom-nav-link">
								ABOUT US
							</Nav.Link>
							<Nav.Link href="#gallery" className="custom-nav-link">
								GALLERY
							</Nav.Link>
							<Nav.Link href="#contact" className="custom-nav-link">
								CONTACT US
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<div id="home">
					<div
						className="hero-section d-none d-md-flex"
						style={{ background: "#98b4d8", color: "black" }}>
						<div className="hero-content">
							<h1>Bhuvan's Homeopathy Clinic</h1>
							<h3>No Antibiotics, No Painkillers, No Side Effects</h3>
						</div>
					</div>
					<div
						className="hero-section d-flex d-md-none flex-column align-items-center"
						style={{ background: "#98b4d8", color: "black" }}>
						<div className="hero-content">
							<h6>Bhuvan's Homeopathy Clinic</h6>
							<h6>No Antibiotics, No Painkillers, No Side Effects</h6>
						</div>
					</div>
				</div>
				<Container id="aboutus" className="my-5">
					<h2 className="text-center">About Us</h2>
					<Row className="align-items-center">
						<Col md={4} className="text-center">
							<img
								src="/Doc_new_bhuvans.jpg"
								className="img-fluid rounded-circle"
								alt="About Us"
							/>
						</Col>
						<Col md={8}>
							<h3>Excellence in Homeopathy in Kamareddy</h3>
							<p>
								Experience the Healing Touch of Nature with Dr. Pathuri
								Rajashekhar Reddy at Bhuvan's Homeopathy. With over two decades
								of expertise, we provide personalized, natural remedies that
								restore balance and vitality. Join us on your journey to
								holistic well-being today.
							</p>
							<Row>
								<Col md={4} className="text-center">
									<CountUp start={0} end={25} duration={2} delay={0}>
										{({ countUpRef }) => (
											<div>
												<span ref={countUpRef} />
												<p>Years of Experience</p>
											</div>
										)}
									</CountUp>
								</Col>
								<Col md={4} className="text-center">
									<CountUp start={0} end={25000} duration={2} delay={0}>
										{({ countUpRef }) => (
											<div>
												<span ref={countUpRef} />+<p>Happy Patients</p>
											</div>
										)}
									</CountUp>
								</Col>
								{/* <Col md={4} className='text-center'>
								<CountUp start={0} end={20} duration={2} delay={0}>
									{({ countUpRef }) => (
										<div>
											<span ref={countUpRef} />
											<p>Dentists</p>
										</div>
									)}
								</CountUp>
							</Col> */}
							</Row>
						</Col>
					</Row>
				</Container>
				<section className="services-section" id="gallery">
					<h2 className="mb-5">Explore Our Services</h2>
					<Row className="justify-content-center m-3">
						{servicesData.map((service, index) => (
							<Col md={3} className="mb-4" key={index}>
								<Card className="service-card">
									<Card.Img
										variant="top"
										src={service.imagePath}
										alt={service.title}
									/>
									<Card.Body>
										<Card.Title>{service.title}</Card.Title>
										<Card.Text>{service.description}</Card.Text>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				</section>
				<Container id="gallery" className="my-5">
					<h2 className="text-center">Clinic Gallery</h2>
					<Row>
						<Col md={4} className="gallery-item">
							<img
								src="/imagegallery1.jpg"
								className="img-fluid"
								alt="Gallery 1"
							/>
						</Col>
						<Col md={4} className="gallery-item">
							<img
								src="/bhuvans_clinic2.jpg"
								className="img-fluid"
								alt="Gallery 2"
							/>
						</Col>
						<Col md={4} className="gallery-item">
							<img
								src="/imagegallery2.jpg"
								className="img-fluid"
								alt="Gallery 3"
							/>
						</Col>
					</Row>
				</Container>
				<footer
					id="contact"
					className="text-center py-4"
					style={{ backgroundColor: "#f8f9fa" }}>
					<Container>
						<Row>
							<Col>
								<iframe
									frameborder="0"
									scrolling="no"
									marginheight="0"
									marginwidth="0"
									id="gmap_canvas"
									src="https://maps.google.com/maps?q=bhuvan's%20homeopathy%20clinic%20kamareddy&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
							</Col>
							<Col>
								<h5>Get in Touch</h5>
								<p>
									<i className="fas fa-phone"></i> +91-9494260222
								</p>
								<p>
									<i className="fas fa-envelope"></i>{" "}
									bhuvanshomeopathyclinic@gmail.com
								</p>
							</Col>
							<Col>
								<h5>Follow Us</h5>
								<div className="social-icons">
									<i className="fab fa-facebook-f"></i>
									<i className="fab fa-twitter"></i>
									<i className="fab fa-instagram"></i>
									<i className="fab fa-linkedin-in"></i>
								</div>
							</Col>
						</Row>
					</Container>
				</footer>
			</div>
		</>
	);
}

export default MainComponentClinic;
